<template>
  <div class="home">
    <div class="content-wrap">
      <header class="reservation-header">
        <h1>Ihre Reservierung</h1>
        <p>Vielen Dank für Ihre Reservierung.</p>
        <p v-if="reservation.status == 'booked'">
          <strong
            >Bitte beachten: Ihre Reservierung wird erst hinterlegt sobald diese
            erfolgreich bezahlt wurde.</strong
          >
        </p>
      </header>

      <div v-show="validate_sofort" style="margin-bottom: 1000px">
        <div class="loading-wrap">
          <div class="loading-spinner"></div>
          <p style="text-align: center; font-size: 14px">
            Vielen Dank, wir prüfen gerade Ihre Zahlung...
          </p>
        </div>
      </div>

      <div v-if="payment_loading">
        <div class="loading-wrap">
          <div class="loading-spinner"></div>
          <p style="text-align: center; font-size: 14px">
            Bezahlung wird durchgeführt. Bitte warten.
          </p>
        </div>
      </div>
      <div v-else>
        <div v-if="reservation.status == 'booked'" class="reservation-status">
          <p>Ihre Reservierung ist aktuell noch nicht bezahlt.</p>
        </div>

        <div
          v-if="reservation.status == 'paid'"
          class="reservation-status-paid"
        >
          <p>
            Der Bezahlvorgang wurde erfolgreich abgeschlossen.<br />Eine
            Bestellbestätigung haben wir Ihnen per E-Mail zukommen lassen.
          </p>
        </div>
      </div>

      <div class="pay-wrap row row-gutter-20" id="pay-wrap">
        <div class="col-12">
          <div
            v-show="reservation.payment_option == 'sofort'"
            id="payment-sofort"
            class="res-p payment-sofort payment-method-wrap"
          >
            <h3
              v-if="reservation.status != 'paid'"
              style="font-weight: 700; font-size: 26px; text-align: center"
            >
              Jetzt bezahlen
            </h3>

            <p style="text-align: center">
              <a
                @click="stripe_sofort_action"
                v-if="intent.stripe_client_secret"
                class="button button-100"
                style="background: #f39200; color: #fff"
                >Jetzt mit SOFORT bezahlen ›</a
              >
            </p>
            <p
              v-if="sofort_loading"
              style="text-align: center; font-size: 14px; margin: 0"
            >
              Lädt, bitte warten...
            </p>
          </div>

          <div
            v-show="reservation.payment_option == 'klarna'"
            id="payment-sofort"
            class="res-p payment-sofort payment-method-wrap"
          >
            <h3
              v-if="reservation.status != 'paid'"
              style="font-weight: 700; font-size: 26px; text-align: center"
            >
              Jetzt bezahlen
            </h3>

            <p style="text-align: center">
              <a
                @click="stripe_klarna_action"
                v-if="intent.stripe_client_secret"
                class="button button-100"
                style="background: #f39200; color: #fff"
                >Jetzt mit Klarna bezahlen ›</a
              >
            </p>
            <p
              v-if="sofort_loading"
              style="text-align: center; font-size: 14px; margin: 0"
            >
              Lädt, bitte warten...
            </p>
          </div>

          <div
            v-show="reservation.payment_option == 'card'"
            id="payment-card"
            class="res-p payment-card payment-method-wrap"
          >
            <h3 style="font-weight: 700; font-size: 26px; text-align: center">
              Jetzt bezahlen
            </h3>

            <div class="form-wrap">
              <label for="cardholder-name">Name</label>
              <input id="cardholder-name" type="text" class="form-input" />
            </div>

            <form id="card-form">
              <div class="form-wrap">
                <label for="cardholder-name">Kreditkartendetails</label>
                <div id="card-element"></div>
              </div>

              <button
                v-show="intent.stripe_client_secret"
                :data-secret="intent.stripe_client_secret"
                id="card-button"
                class="button button-orange button-100 button-small"
              >
                Jetzt bezahlen ›
              </button>
            </form>
          </div>

          <div
            v-show="reservation.payment_option == 'sepa'"
            id="payment-sepa"
            class="res-p payment-sepa payment-method-wrap"
          >
            <h3>Jetzt bezahlen</h3>

            <form action="/charge" method="post" id="sepa-form">
              <div class="form-wrap">
                <label for="name">Name</label>
                <input
                  id="sepa-name"
                  name="name"
                  placeholder="Max Mustermann"
                  class="form-input"
                  required
                />
              </div>

              <div class="form-wrap">
                <label for="email">E-Mail Adresse</label>
                <input
                  id="sepa-email"
                  name="email"
                  type="email"
                  placeholder="max.mustermann@example.com"
                  class="form-input"
                  required
                />
              </div>

              <div class="form-wrap">
                <label for="iban-element">IBAN</label>
                <div id="iban-element"></div>
              </div>

              <button
                v-show="intent.stripe_client_secret"
                id="sepa-button"
                class="button button-orange button-100 button-small"
                :data-secret="intent.stripe_client_secret"
              >
                Jetzt bezahlen ›
              </button>

              <div id="error-message" role="alert"></div>

              <div id="mandate-acceptance" class="disclaimer">
                Durch die Eingabe Ihrer IBAN und durch das Bestätigen der
                Bezahlung, authorisieren Sie (A) InTime Autovermietung und
                Stripe, unseren Bezahldienstleister, die SEPA-Lastschrift bei
                (B) Ihrer Bank abzubuchen. Sollte die Buchung fehlerhaft
                durchgeführt worden sein, haben Sie das Recht die Buchung
                innerhalb von 8 Wochen bei Ihrer Bank und der dort geltenden
                Bedingungen rückgängig zu machen.
              </div>
            </form>
          </div>
        </div>
        <div class="col-12">
          <table
            class="table"
            style="border: 1px solid #dee2e6; margin-bottom: 40px"
          >
            <thead>
              <tr>
                <th width="50%">Beschreibung</th>
                <th width="50%" style="text-align: right">Kosten</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in reservation.res_items" v-bind:key="item.name">
                <td>{{ item.name }}</td>
                <td style="text-align: right">
                  {{ parseFloat(item.price).toFixed(2) }} €
                </td>
              </tr>

              <tr
                style="
                  background: #f6f9fd;
                  color: #495057;
                  border-top: 3px solid rgb(222, 226, 230);
                "
              >
                <td><strong>Gesamtbetrag (Netto)</strong></td>
                <td style="text-align: right">
                  {{ parseFloat(reservation.sum_net).toFixed(2) }} €
                </td>
              </tr>
              <tr style="background: #f6f9fd; color: #495057">
                <td>
                  <strong>+ {{ reservation.tax_rate }}% MwSt</strong>
                </td>
                <td style="text-align: right">
                  {{ parseFloat(reservation.sum_tax).toFixed(2) }} €
                </td>
              </tr>
              <tr style="background: #444; color: #fff">
                <td><strong>GESAMTBETRAG</strong></td>
                <td style="text-align: right">
                  <strong
                    >{{
                      parseFloat(reservation.sum_gross).toFixed(2)
                    }}
                    €</strong
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="reservation-support">
        <h3>Fragen?</h3>
        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="contact-wrap">
              <p class="icon"><span class="material-icons">call</span></p>
              <h4>0157 83666161</h4>
            </div>
          </div>
          <div class="col-12">
            <div class="contact-wrap">
              <p class="icon"><span class="material-icons">email</span></p>
              <h4>info@intime-autovermietung.de</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "new_reservation",
  data() {
    return {
      reservation: {},
      loading: false,
      sofort_loading: false,
      stripe: "",
      stripe_elements: "",
      intent: {},
      payment_loading: false,
      validate_sofort: false,
      sofort_patched: false,
    };
  },
  methods: {
    get_data() {
      axios
        .get(
          process.env.VUE_APP_BASE_API +
            "/v1/reservations/" +
            this.$route.params.uuid,
          {}
        )
        .then((response) => {
          this.reservation = response.data.reservation;
          if (this.reservation.status == "booked") {
            console.log("JOJO222");
            document
              .getElementById("pay-wrap")
              .classList.add("payment-visible");
            this.init_stripe();
            if (this.$route.query.sofort) {
              if (this.sofort_patched == false) {
                this.patch_sofort_action();
                this.validate_sofort = true;
              }
            } else if (this.$route.query.klarna) {
              if (this.sofort_patched == false) {
                this.patch_sofort_action();
                this.validate_sofort = true;
              }
            } else {
              setTimeout(() => {
                this.create_payment_intent();
              }, 500);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    create_payment_intent() {
      var amount = String(parseFloat(this.reservation.sum_gross).toFixed(2))
        .split(".")
        .join("");

      axios
        .get(
          process.env.VUE_APP_BASE_API +
            "/v1/payment_intent?amount=" +
            amount +
            "&reservation_id=" +
            this.reservation.uuid,
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.intent = response.data;
          if (this.reservation.payment_option == "card") {
            this.stripe_card_action();
          } else if (this.reservation.payment_option == "sepa") {
            this.stripe_sepa_action();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stripe_sofort_action() {
      this.sofort_loading = true;

      this.stripe
        .confirmSofortPayment(this.intent.stripe_client_secret, {
          payment_method: {
            sofort: {
              country: "DE",
            },
          },
          return_url:
            "https://intime-autovermietung.de/reservations/" +
            this.reservation.uuid +
            "?sofort=true",
        })
        .then(function (result) {
          console.log(result);
        });
    },
    stripe_klarna_action() {
      this.sofort_loading = true;

      this.stripe
        .confirmKlarnaPayment(this.intent.stripe_client_secret, {
          payment_method: {
            klarna: {},
            billing_details: {
              address: {
                country: "DE",
              },
              name: `${this.reservation.first_name} ${this.reservation.last_name}`,
              email: `${this.reservation.email}`,
            },
          },
          return_url:
            "https://intime-autovermietung.de/reservations/" +
            this.reservation.uuid +
            "?klarna=true",
        })
        .then(function (result) {
          console.log(result);
        });
    },
    patch_sofort_action() {
      this.sofort_patched = true;
      axios
        .patch(
          process.env.VUE_APP_BASE_API +
            "/v1/reservations/" +
            this.$route.params.uuid,
          {
            pi: this.$route.query.payment_intent,
          },
          {}
        )
        .then((response) => {
          console.log("response", response);
          this.payment_loading = false;
          this.validate_sofort = false;
          this.get_data();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "alert",
              type: "error",
              title: "Fehler",
              text: value,
            });
          });
        });
    },
    stripe_sepa_action() {
      var elements = this.stripe.elements();

      var style = {
        base: {
          color: "#32325d",
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
          ":-webkit-autofill": {
            color: "#32325d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
          ":-webkit-autofill": {
            color: "#fa755a",
          },
        },
      };

      var iban = elements.create("iban", {
        style: style,
        supportedCountries: ["SEPA"],
      });

      iban.mount("#iban-element");

      var form = document.getElementById("sepa-form");
      var accountholderName = document.getElementById("sepa-name");
      var email = document.getElementById("sepa-email");
      var clientSecret = this.intent.stripe_client_secret;

      var that = this;
      form.addEventListener("submit", function (event) {
        event.preventDefault();

        that.stripe
          .confirmSepaDebitPayment(clientSecret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: accountholderName.value,
                email: email.value,
              },
            },
          })
          .then(function (result) {
            if (result.error) {
              console.log(result.error);
            } else {
              that.payment_loading = true;

              document
                .getElementById("pay-wrap")
                .classList.remove("payment-visible");

              axios
                .patch(
                  process.env.VUE_APP_BASE_API +
                    "/v1/reservations/" +
                    that.$route.params.uuid,
                  {
                    pi: that.intent.stripe_id,
                  },
                  {}
                )
                .then((response) => {
                  console.log(response);
                  that.payment_loading = false;
                  that.get_data();
                })
                .catch((error) => {
                  error.response.data.errors.forEach((value) => {
                    that.$notify({
                      group: "alert",
                      type: "error",
                      title: "Fehler",
                      text: value,
                    });
                  });
                });
            }
          });
      });
    },
    stripe_card_action() {
      var elements = this.stripe.elements();
      var card_element = elements.create("card");

      card_element.mount("#card-element");

      var form = document.getElementById("card-form");
      var cardholderName = document.getElementById("cardholder-name");
      var clientSecret = this.intent.stripe_client_secret;
      var that = this;

      form.addEventListener("submit", function (event) {
        event.preventDefault();

        that.stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: card_element,
              billing_details: {
                name: cardholderName.value,
              },
            },
          })
          .then(function (result) {
            if (result.error) {
              console.log(that.intent.stripe_client_secret);
              console.log(result.error);
            } else {
              that.payment_loading = true;
              that.loading = true;
              document
                .getElementById("pay-wrap")
                .classList.remove("payment-visible");

              console.log(that.intent.stripe_id);

              axios
                .patch(
                  process.env.VUE_APP_BASE_API +
                    "/v1/reservations/" +
                    that.$route.params.uuid,
                  {
                    pi: that.intent.stripe_id,
                  },
                  {}
                )
                .then((response) => {
                  console.log(response);
                  that.payment_loading = false;
                  that.get_data();
                })
                .catch((error) => {
                  error.response.data.errors.forEach((value) => {
                    that.$notify({
                      group: "alert",
                      type: "error",
                      title: "Fehler",
                      text: value,
                    });
                  });
                });
            }
          });
      });
    },
    init_stripe() {
      const Stripe = window.Stripe;
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.pay-wrap {
  width: 80%;
  margin: 30px auto;
  position: absolute;
  visibility: hidden;
  left: -50000px;
  top: -50000px;
}

.res-p {
  position: initial !important;
  visibility: visible !important;
}

.reservation-header {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  h1 {
    font-size: 32px;
  }

  p {
    margin: 10px 0 0 0;
  }
}

.reservation-status {
  width: 80%;
  background-color: #f8d7da;
  color: #721c24;
  display: block;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  margin: auto;

  p {
    margin: 0;
  }
}

.reservation-status-paid {
  width: 80%;
  background-color: #d4edda;
  color: #155724;
  display: block;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  margin: auto;

  p {
    margin: 0;
  }
}

.reservation-support {
  width: 80%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;

  h3 {
    text-align: center;
  }
  .support-wrap {
    background: #f9f9f9;
    border-radius: 5px;
  }

  .contact-wrap {
    background: #f9f9f9;
    text-align: center;
    padding: 15px;
    font-size: 18px;

    p.icon {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;

      span {
        font-size: 20px;
        position: relative;
        top: 3px;
      }
    }

    h4 {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
}

.payment-visible {
  position: initial;
  visibility: visible;
}
</style>
